@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');


body {
    background-color: #fefae0;
    background-image: url('../assets/images/bg1.png');
    background-repeat: repeat;
    color: #283618;

    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: normal;
}

h4, strong {
    font-weight: 700;
}

a {
    color: #283618;

    &:hover {
        color: #606c38;
    }
}

a.holiday {
    color: #BC6C25;

    &:hover {
        color: #DDA15E;
    }
}

th.calendar-header {
    width: 14.28%;
    background-color: #BC6C25;
    color: white;
}